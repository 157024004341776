import { Routes, Route } from 'react-router-dom'
import ProjectList from './ProjectList'

import ProjectDetail from './ProjectDetail'

function App() {
    return (
        <Routes>
            <Route path='/:token' element={<ProjectList />} />
            <Route path='/detail/:projectId/:token' element={<ProjectDetail />} />
        </Routes>
    )
}

export default App
